.main-page {

    &__sell-baner {

        @media only screen and (max-width: 950px)  {
            display: none;
        }

        position: fixed;


        // transform: translateX(100%);
        transition: .3s ease-out;
        // animation: moveToLeft 0.3s ease-in-out 2s forwards;

        // @keyframes moveToLeft {
        //     to {
        //         transform: translateX(0%);
        //     }
        // }

        &--active {
            transform: translateX(0%);

        }

        &--inactive {
            transform: translateX(80%);
        }
        

        top: 220px;     //  <- for active baner
        // top: 180px;
        right: 0px;

        z-index: 3;


        &__headline {


            
            cursor: pointer;

            &--long {
                padding-left: 20px;
            }

            &--short {
                padding-left: 0px;
            }

            &--active {

                &:hover {

    
                    transform: translateX(5%);
                }
    
                &:hover + .main-page__sell-baner__courses {
                    transform: translateX(10%);
                }
            }

            &--inactive {

                &:hover {
                    transform: translateX(-5%);
                }
    
                &:hover + .main-page__sell-baner__courses {
                    transform: translateX(-10%);
                }
            }


            

            transition: .3s ease-out;
            // animation: moveToLeft 0.3s ease-in-out 3s forwards;
    
            user-select: none;

            padding-top: 8px;

            font-weight: 600;


            border-top-left-radius: 15px;
            border-bottom-left-radius: 15px;
    
 
            height: 113px;

            width: 261px;
    
            border-top-left-radius: 15px;
            border-bottom-left-radius: 15px;
    
    
            background-color: #ffff;
            border-bottom: 2px solid #eaeaea;

            text-align: left;

            -webkit-box-shadow: 8px 8px 24px -16px rgba(66, 68, 90, 1);
            -moz-box-shadow: 8px 8px 24px -16px rgba(66, 68, 90, 1);
            box-shadow: 8px 8px 24px -16px rgba(66, 68, 90, 1);


            &__row-1 {
                position: relative;
                display: flex;
            

                &__trophy {
                    display: block;

                    position: relative;
                    top: 9px;


                    font-size: 35px;

                    &--long {
                        padding-left: 14px;
                    }
        
                    &--short {
                        padding-left: 10px;
                    }
                }
            }

            span {
                position: relative;
                top: 14px;

                cursor: pointer;
            }

            font-size: 35px;

            b { 
                font-size: 38px;
            
                color: #8f2c5f;
                text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.202);
            }




            svg {
             
                width: 29px;
                height: 29px;

                position: relative;
   
                top: 4px;
            }

            .long {
                svg {
                    left: 7px;
                }
            }

            .short {
                svg {
                    left: 12px;
                    transform: rotate(180deg);
                }
            }

        }

        &__courses {

            transition: .3s ease-out;

            &__course {


                // position: relative;
                // right: 0;

                border-top-left-radius: 12px;
                border-bottom-left-radius: 12px;
    

                border-top-right-radius: 12px;
                border-bottom-right-radius: 12px;

                margin-top: 8px;

                width: 270px;


                // background-color: #ffff;

                // color: #ffff;

                font-size: 20px;
                font-weight: 500;

                position: relative;
                display: flex;

                background-color: #fdc22d;
                border-bottom: 2px solid #d19808;


                cursor: pointer;

                transition: transform 0.3s ease, background-color 0.3s ease, border-bottom 0.3s ease;

                &:hover {
                    transform: scale(1.03);

                    background-color: #f4ba28;
                    border-bottom: 2px solid #c89105;
                }

                &:hover .number {
                    display: none;
                }

                &:hover svg {
                    display: block;
                }

                svg {
                    display: none;
                }

                // background-color: #38a3b1;
                // border-bottom: 2px solid rgb(38, 91, 107);

                &__img-wrapper {
                    // background-color:#0d0d2b;

                    width: 40px;            
                    height: 45px;
                    border-radius: 9px;

                    svg {

                        position: relative;
                        top: 10px;
                        padding-left: 14px;
                        transform: scale(1.1);
                    }
    
                    span {
                        font-weight: 550;
                    }                

                }


                span {
                    display: block;
                    padding-top: 11px;
                    padding-left: 15px;
                }



                -webkit-box-shadow: 8px 8px 24px -16px rgba(66, 68, 90, 1);
                -moz-box-shadow: 8px 8px 24px -16px rgba(66, 68, 90, 1);
                box-shadow: 8px 8px 24px -16px rgba(66, 68, 90, 1);
    
            }
    
        }

    }
}