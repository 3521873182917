.main-page {
  --main-panel-radius: calc(var(--panel-radius) + 5px);
  --margin-main: 3.5%;

  position: relative;

  @media only screen and (max-width: 950px) {
    --main-panel-margin: 10px;
  }

  @media only screen and (min-width: 951px) {
    --main-panel-margin: 20px;
  }

  .yellow-button {
    cursor: pointer;

    @media only screen and (max-width: 950px) {
      font-weight: 600;
    }


    background-color: var(--yellow-color);
    font-weight: 500;

    -webkit-user-select: none;
    user-select: none;

    &:hover {
      background-color: #ffbe1a !important;
      border-color: #ffbe1a !important;
    }

    &:active {
      transform: scale(0.98);
    }
  }

  &__introduction {

    @media only screen and (min-width: 951px) {
      display: none;
    }

    
    img {
      @media only screen and (max-width: 950px) {
        display: none;
      }
    }

    .arena {

      transform: scale(1.25);

      @media only screen and (min-width: 951px) and (max-width: 1180px) { 
        opacity: 0.3330;
      }

      @media only screen and (min-width: 1181px) {
        opacity: 0.55;
      }

      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;

      height: 73%;
    }

    @media only screen and (min-width: 951px) {
      margin-top: 180px;

      // margin-top: 140px;
    }



    margin-left: var(--main-panel-margin);
    margin-right: var(--main-panel-margin);

    border-radius: var(--main-panel-radius);

    position: relative;
    display: flex;

    overflow: hidden;

    -webkit-box-shadow: 2px 10px 24px -12px rgba(66, 68, 90, 1);
    -moz-box-shadow: 2px 10px 24px -12px rgba(66, 68, 90, 1);
    box-shadow: 2px 10px 24px -12px rgba(66, 68, 90, 1);

    background-color: rgba(0, 119, 122, 1);
    background-image: linear-gradient(
      175deg,
      rgba(0, 119, 122, 1) 0%,
      rgba(0, 81, 83, 1) 31%,
      rgba(0, 48, 49, 1) 63%,
      rgba(0, 32, 32, 1) 78%,
      rgba(0, 20, 20, 1) 88%
    );

    @media only screen and (min-width: 951px) {
      height: 575px;
    }

    .down-left-ray {
      top: -10px;
      left: -5px;

      position: absolute;
      z-index: 1;

      opacity: 0.5;


      @media only screen and (max-width: 950px) {
        height: 700px;
        left: 45px;
        top: 30px;
        transform: scale(1.2);
      }
    }

    .top-right-ray {
      position: absolute;
      z-index: 1;

      top: -5px;
      right: -5px;

      @media only screen and (max-width: 950px) {
        display: none;
      }
    }

    &__left-section {
      height: 100%;

      @media only screen and (max-width: 950px) {
        width: 100%;
        padding-top: 40px;
      }

      @media only screen and (min-width: 951px) {
        padding-top: 5%;
        padding-left: 5%;
      }

      @media only screen and (min-width: 951px) and (max-width: 1180px) { 
        width: 80%;
      }
    
      @media only screen and (min-width: 1181px) { 
        width: 60%;
      }

      z-index: 2;

      &__headline {
        font-weight: 500;

        span {
          display: block;
        }

        @media only screen and (max-width: 950px) {
          padding-left: 5%;
        }

        &__text-1 {
          @media only screen and (max-width: 950px) {
            font-size: 40px;
            // margin-right: 30%;
          }

          @media only screen and (min-width: 951px) {
            font-size: 50px;
          }

          color: #ffff;
          margin-bottom: 5px;

          &.desktop {
            @media only screen and (max-width: 950px) {
              display: none;
            }
          }

          &.mobile {
            b {

              font-size: 25px;
            }

            font-size: 39px;

            @media only screen and (min-width: 951px) {
              display: none;
            }
          }
        }

        &__text-2 {
          @media only screen and (max-width: 950px) {
            margin-top: 12px;
            font-size: 30px;
          }

          @media only screen and (min-width: 951px) {
            font-size: 41px;
          }

          color: var(--yellow-color);

          text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.477);
        
          &.desktop {
            @media only screen and (max-width: 950px) {
              display: none;
            }
          }

          &.mobile {

            @media only screen and (min-width: 951px) {
              display: none;
            }
          }
        }
      }

      &__bullet-points {

        max-width: 900px;

        @media only screen and (max-width: 950px) {
          margin-top: 30px;
          padding-left: 5%;
        }

        @media only screen and (min-width: 951px) {
          margin-top: 60px;

          --column-gap: 20px;

          display: grid;
          grid-template-columns: var(--column-width) var(--column-width);
          grid-row: auto auto;
          grid-column-gap: var(--column-gap);
          grid-row-gap: 30px;



        }

        @media only screen and (min-width: 951px) and (max-width: 1180px) { 
          --column-width: calc(70% - var(--column-gap) * 0.5);

        }
      
        @media only screen and (min-width: 1181px) { 
          --column-width: calc(60% - var(--column-gap) * 0.5);

        }


        &__bullet-point {
          display: flex;

          @media only screen and (max-width: 950px) {
            margin-bottom: 22px;
            padding-right: 5%;
          }

          @media only screen and (min-width: 951px) {
            padding-right: 40px;
          }

          div {
            display: flex;
            flex-direction: column;
            justify-content: center;
          }

          span {
            color: #ffff;
            font-size: 18px;

            font-size: 20px;
            line-height: 26px;
          }

          .material-symbols-outlined {
            font-size: 30px;
            // font-size: 27px;
            font-variation-settings:
            'FILL' 0,
            'wght' 400,
            'GRAD' 0,
            'opsz' 24;

            color: var(--yellow-color);

            margin-right: 12px;
          }

          b {
            font-weight: 500;
            color: var(--yellow-color);

          }

          &__checkmark {
            width: 39px !important;
            min-width: 39px !important;

            height: auto;
            display: flex;

            svg {
              margin: auto 0;
              display: block;
              top: -2px;

              width: 24px;
              height: 24px;

              position: relative;
            }
          }
        }
      }

      &__buttons {
        @media only screen and (max-width: 950px) {
          margin-top: 50px;
          margin-bottom: 50px;
        }

        @media only screen and (min-width: 951px) {
          margin-top: 100px;
          display: flex;
        }

        &__button {
          border-radius: var(--main-panel-radius);
          cursor: pointer;

          @media only screen and (max-width: 950px) {
            margin-left: auto;
            margin-right: auto;

            width: 75%;
            display: flex;
            justify-content: center;
            font-weight: 600;
          }

          @media only screen and (min-width: 951px) {
            padding: 16px 24px 16px 24px;
            font-weight: 500;
          }

          &--left {
            @media only screen and (max-width: 950px) {
              padding: 18px 24px 18px 24px;
            }

            @media only screen and (min-width: 951px) {
              padding: 16px 24px 16px 24px;
            }
          }

          &--right {

            padding: 6px 14px 16px 24px;

            position: relative;

            background-color: #ffff;

            &:hover {
              color: #ffff;
              background-color: #8f2c5f;
              transition: background-color 0.3s ease;

              svg {
                filter: brightness(0) invert(1);
              }
            }

            &:active {
              transform: scale(0.98);
            }

            @media only screen and (max-width: 950px) {
              margin-top: 25px;
              padding: 10px 24px 20px 24px;

              span {
                display: block;
                position: relative;
                top: 9px;
              }
            }

            @media only screen and (min-width: 951px) {
              margin-left: 20px;
            }

            svg {
              margin-left: 12px;
              position: relative;
              top: 7px;
            }
          }
        }
      }
    }

    &__right-section {
      width: 40%;
      height: 100%;

      z-index: 2;

      display: flex;

      @media only screen and (max-width: 950px) {
        display: none;
      }
    }
  }


  &__go-to-offers {
    display: flex;
    justify-content: center;

    margin-bottom: 100px;

    &__wrapper {
      width: 70%;
      max-width: 743px;

      &__main-text {

        span {
          width: 100%;
          font-size: 20px;
          line-height: 28px;
        }

      }

      &__headline {
        font-weight: 500 !important;
        font-size: 40px;
        color: #8f2c5f;
        text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.202);

        text-align: center;
        margin-bottom: 20px;



        @media only screen and (max-width: 950px) {
          margin-bottom: 20px;
        }

      }

      &__main-text {
        text-align: center;

        b {
          // text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.17);
          // font-weight: 400;
          color: #8f2c5f;
        }
      }

      &__button {
        margin-top: 40px;

        width: min-content;

        display: flex;

        margin-left: auto;
        margin-right: auto;

        font-weight: 500;
        white-space: nowrap;

        cursor: pointer;

        border-radius: var(--main-panel-radius);

        padding: 16px 16px 9px 24px;
        position: relative;
        background-color: var(--yellow-color);

        svg {
          position: relative;
          top: -3px;
          margin-left: 10px;
        }
      }
    }
  }


}
