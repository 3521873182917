
.line-breaks {

    height: 14px;
    content: " ";

    &--small {
        height: 3px;
    }
}

.img-wrapper {
    margin-top: 20px;
    display: flex;

    img {
        border-radius: 15px;
        max-width: 535px;
        margin-left: auto;
        margin-right: auto;
        width: 95%;
        height: auto;

        pointer-events: none;
        user-select: none;
        margin: 10px;
    }
}

.block {
    &__code {
        margin: 20px !important;

        &__button-wrapper {
            width: 100%; 
            position: relative;
        }

        &__programming-language {
            margin-top: 0;
            margin-bottom: 0;

            display: inline;
            position: absolute;
            left: 20px;
            top: 20px;
            color: white;
            border-bottom: 2px solid var(--highlited-color);
            padding: 10px;
            text-align: center;
            -webkit-user-select: none; /* Safari */
            -ms-user-select: none; /* IE 10 and IE 11 */
            user-select: none; /* Standard syntax */
        }

        &__button {
            margin-top: 0;
            margin-bottom: 0;
            border-radius: 8px;
            display: inline;
            position: absolute;
            right: 20px;
            top: 20px;
            color: white;
            border: 2px solid var(--highlited-color);
            padding: 10px;
            text-align: center;
            cursor: pointer;
            -webkit-user-select: none; /* Safari */
            -ms-user-select: none; /* IE 10 and IE 11 */
            user-select: none; /* Standard syntax */

            &:active {
                border: 2px solid var(--yellow-color);
            }
        }
    }
}